<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"
       @click="$emit('click')">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M12.5 23.5C6.42487 23.5 1.5 18.5751 1.5 12.5C1.5 6.42487 6.42487 1.5 12.5 1.5C18.5751 1.5 23.5 6.42487 23.5 12.5C23.5 18.5751 18.5751 23.5 12.5 23.5ZM0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5ZM11.5 11.5V6.5H13.5V11.5H18.5V13.5H13.5V18.5H11.5V13.5H6.5V11.5H11.5Z"
          :fill="fill || '#8BD118'"/>
  </svg>
</template>

<script>
  import icon_config from "@/mixins/icon_config";

  export default {
    name: "IconPlusSmall",
    mixins: [icon_config],

  }
</script>

<style scoped>

</style>
