<template>
  <svg :width=" width ? width : 25" :height=" height ? height : 25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <g :opacity=" fill ? 1 : 0.3">
      <path d="M18.5 13.5V11.5L6.5 11.5V13.5L18.5 13.5Z"  :fill="fill || 'black'"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5ZM12.5 23.5C6.42487 23.5 1.5 18.5751 1.5 12.5C1.5 6.42487 6.42487 1.5 12.5 1.5C18.5751 1.5 23.5 6.42487 23.5 12.5C23.5 18.5751 18.5751 23.5 12.5 23.5Z" :fill="fill || 'black'"/>
    </g>
  </svg>

</template>

<script>
import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconMinus",
      mixins: [icon_config],
    }
</script>

<style scoped>

</style>
