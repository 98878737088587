<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"

          :md="4"
          :sm="12"
          :lg="4"
          class="py-0 my-0"
          style="min-width: 180px"
        >
          <v-subheader class="my-2">
            {{ $t('offer.creation.object_temperature_regime') }}
          </v-subheader>
          <v-radio-group
            v-model="form.temperature"
            dense
            hide-details
            class="my-0 py-0"
          >
            <v-radio
              v-for="radio in radioButton"
              :key="radio.value"
              color="success"
              :name="radio.name"
              :value="radio.value"
              :label="radio.label"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"

          :md="4"
          :sm="12"
          :lg="4"
          class="py-0 my-0"
          style="min-width: 180px"
        >
          <v-subheader class="my-2">
            {{ $t('Условия работы') }}
          </v-subheader>
          <v-checkbox
            v-model="form.hasTransportation"
            color="success"
            hide-details
            dense
            :label="$t('offer.creation.corporate_transport')"
          />
          <v-checkbox
            v-model="form.freeFood"
            color="success"
            dense
            hide-details
            :label="$t('offer.creation.free_lunch')"
          />
        </v-col>
        <v-col
          v-if="!isPersonal"
          cols="12"
          :md="4"
          :sm="12"
          :lg="4"
          class="py-0 my-0"
          style="min-width: 180px"
        >
          <v-subheader class="my-2">
            {{ $t('Пол соискателя') }}
          </v-subheader>
          <v-radio-group
            v-model="form.gender"
            dense
            hide-details
            class="my-0 py-0"
          >
            <v-radio
              v-for="radio in genderCollection"
              :key="radio.value"
              color="success"
              :name="radio.name"
              :value="radio.value"
              :label="radio.label"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'OfferVacancySelect',
  components: {
  },
  props: {
    isPersonal: Boolean,
    form: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    radioButton: [
      { label: 'Нормальный', value: 'normal', name: 'temperature' },
      { label: 'Холодный', value: 'cold', name: 'temperature' }
    ],
    genderCollection: [
      { label: 'Не важно', value: '-1', name: 'gender' },
      { label: 'Мужчины', value: 'male', name: 'gender' },
      { label: 'Женщины', value: 'female', name: 'gender' },
    ]
  })
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/create-offer-modal'
</style>

<style lang="sass">
.offer-additional-option-item-header
  margin-bottom: 12px
  font-weight: bold
.temp-radio
  margin-top: 4px
.selector
  .input-date-wide
    width: 45px
</style>
