<template>
  <v-container>
    <v-radio-group
      v-model="startDateConfig.mode"
      row
    >
      <v-radio
        v-for="(radio) in offerDateSelectionSet"
        :key="radio.value"
        :name="radio.name"
        :value="radio.value"
        :label="radio.label"
        :color="startDateConfig.mode ? 'success' : 'error'"
        @change="onCalendarModeChange(radio.value)"
      >
        <template v-slot:label>
          <div><strong class="error--text">{{ radio.label }}</strong></div>
        </template>
      </v-radio>
    </v-radio-group>
    <v-row no-gutters>
      <v-col
        class="py-0 my-0"
        :md="4"
        :sm="12"
        style="min-width: 200px !important;"
      >
        <v-card-actions>
          <div class="mx-1">
            <span class="small-text">{{ 'Период смен' }}:</span>
            <v-card-actions>
              <div
                class="date-tabs__tab date-tabs__tab--active"
                @click="changeDateFilter()"
              >
                <template>
                  <div
                    v-if="!isPeriod"
                    class="period-wrapper"
                  >
                    <span v-if="startPeriod">
                      <span>{{ startPeriod | moment('DD.MM.YY') }}</span>
                    </span>
                  </div>
                  <div
                    v-else
                    class="period-wrapper"
                  >
                    <span v-if="startPeriod && endPeriod">
                      <span>{{ startPeriod | moment('DD.MM.YY') }}</span>
                      <span class="text-gray">-</span>
                      {{ endPeriod | moment('DD.MM.YY') }}
                    </span>
                  </div>
                  <div>
                    <div
                      v-if="!dateModeSelected"
                      style="display: block !important; position: absolute;
                  font-size: 14px; text-align: center; margin: 0 auto;
                 top: 0;left: 0; bottom: 0; right: 0; border: 1px solid red; z-index: 2; border-radius: 12px"
                    >
                      <span style="display: inline-block; margin-top: 10px; color: red">
                        Выберите тип смены
                      </span>
                    </div>
                    <flat-pickr
                      v-if="dateModeSelected"
                      ref="offerPeriod"
                      v-model="dateRange"
                      class="flatpickr"
                      type="Timestamp"
                      :config="startDateConfig.mode.length > 3 ? startDateConfig: {
                        ...startDateConfig,
                        mode: 'single'
                      }"
                      @on-close="updateCalendar"
                      @on-change="updateCalendar"
                    />
                  </div>
                </template>
              </div>
            </v-card-actions>
          </div>
        </v-card-actions>
      </v-col>
      <v-col
        class="py-0 my-0"
        cols="12"
        :sm="12"
        :md="8"
      >
        <v-row no-gutters>
          <v-col
            class="py-0 my-0"
            cols="2"
            :sm="2"
            :md="3"
          >
            <div class="mx-1">
              <span class="small-text">{{ $t('offer.creation.shift_start') }}:</span>
              <v-card-actions>
                <AppTimeSelector
                  :value="startDate"
                  :max="dateMax"
                  :min="dateMin"
                  type="hours"
                  class="time-selector mr-1"
                  :min-start-date="minStartDate"
                  @increase="$emit('update:startDate', $event)"
                  @decrease="$emit('update:startDate', $event)"
                />
                :
                <AppTimeSelector
                  :value="startDate"
                  :max="dateMax"
                  :min="dateMin"
                  :step="15"
                  type="minutes"
                  class="time-selector ml-1"
                  :min-start-date="minStartDate"
                  @increase="$emit('update:startDate', $event)"
                  @decrease="$emit('update:startDate', $event)"
                />
              </v-card-actions>
            </div>
          </v-col>
          <v-col
            class="py-0 my-0"
            cols="2"
            :sm="2"
            :md="3"
          >
            <div class="mx-1">
              <span class="small-text">{{ $t('offer.creation.shift_end') }}:</span>
              <v-card-actions>
                <AppTimeSelector
                  :value="endDate"
                  :max="endTimeMax"
                  :min="endDateMin"
                  :disableIncrease="!canAddShiftEndHour"
                  type="hours"
                  class="time-selector mr-1"
                  @increase="onShiftEndHourUpdated($event, 'increase')"
                  @decrease="onShiftEndHourUpdated($event, 'decrease')"
                />
                :
                <AppTimeSelector
                  :value="endDate"
                  :max="endTimeMax"
                  :min="endDateMin"
                  :disableIncrease="!canAddShiftEndMinute"
                  :step="15"
                  type="minutes"
                  class="time-selector ml-1"
                  @increase="onShiftEndMinutesUpdated($event, 'increase')"
                  @decrease="onShiftEndMinutesUpdated($event, 'decrease')"
                />
              </v-card-actions>
            </div>
          </v-col>
          <v-col
            class="py-0 my-0"
            cols="2"
            :sm="2"
            :md="3"
          >
            <div class="mx-1">
              <span
                class="small-text"
              >{{ `${$t('offer.creation.shift')}:    ` }}</span>
              <span class="small-text">{{ `${$t('offer.creation.break')}:` }}</span>
              <v-card-actions>
                <AppTimeSelector
                  :value="shiftHours"
                  :max="shiftHoursMax"
                  :min="shiftHoursMin"
                  :disableIncrease="!canAddShiftDurationHour"
                  type="time"
                  class="mr-1"
                  :min-hours="minRelayDuration"
                  @increase="onShiftHoursUpdated($event, 'increase')"
                  @decrease="onShiftHoursUpdated($event, 'decrease')"
                />:
                <AppTimeSelector
                  :value="lunchBreak"
                  :max="lunchBreakMax"
                  :min="lunchBreakMin"
                  :step="15"
                  :disabled="hasLunchBreakSettingMatrix"
                  :disableIncrease="!canAddLunchBreak"
                  :invalid="lunchBreak === ''"
                  type="number"
                  class="input-date-wide ml-1"
                  @click="onEnableAlertData"
                  @increase="onBreakValueUpdated($event, 'increase')"
                  @decrease="onBreakValueUpdated($event, 'decrease')"
                >
                  <template v-if="lunchBreak !== ''">
                    {{ $t('offer.creation.minutes_shortcut') }}
                  </template>
                </AppTimeSelector>
              </v-card-actions>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-divider class="my-8" />
  </v-container>
</template>

<script>
// import AppDateSelector from '@/components/common/AppDateSelector'
import AppTimeSelector from '@/components/common/AppTimeSelector'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import routerList from '@/router/labels'

const OFFER_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'
export default {
  name: 'OfferDatesSelect',
  components: {
    // AppDateSelector,
    AppTimeSelector,
  },
  props: {
    shiftDurationFullFilled: Boolean,
    shiftFullDuration: Number,
    minStartDateInterval: Number,
    minRelayDuration: Number,
    maxRelayDuration: Number,
    minBreakDuration: Number,
    maxBreakDuration: Number,
    minStartDate: Object,
    startDate: String,
    endDate: String,
    isPeriod: Boolean,
    startPeriod: [String, null],
    endPeriod: [String, null],
    shiftHoursFloat: Number,
    shiftHours: [String, Number],
    dayDiff: Number,
    lunchBreak: [String, Number],
    vaccineRequirement: {
      type: Object
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // vaccineRequirement: {
    //   value: null,
    //   optionList: [
    //     { id: '', title: 'not require' },
    //     { id: 'req', title: 'require' },
    //   ]
    // },
    dateModeSelected: false,
    offerDateSelectionSet: [
      { label: 'Одиночная смена', value: 'single', name: 'date-single' },
      { label: 'На несколько смен', value: 'range', name: 'date-range' }
    ],
    dateFilterSelected: 'period',
    dateRange: [],
    startDateConfig: {
      locale: Russian,
      dateFormat: OFFER_DATE_FORMAT,
      altFormat: 'Z',
      mode: '',
      disableMobile: 'true'
      // range
    },
    dateOptions: ['all', 'last_week', 'last_month', 'period'],
    shiftHoursMin: null,
    shiftHoursMax: null,
    lunchBreakMin: null,
    lunchBreakMax: null,
    enableAlertData: false,
    alertData: {
      message: undefined,
      actionName: undefined,
      callback: undefined,
    },
  }),
  computed: {
    canAddLunchBreak () {
      const nextValue = this.shiftFullDuration + 0.25
      return nextValue <= this.getRelayMaxDuration
    },
    canAddShiftDurationHour () {
      const nextValue = this.shiftFullDuration + 1
      return nextValue <= this.getRelayMaxDuration
    },
    canAddShiftEndHour () {
      const nextValue = this.shiftFullDuration + 1
      return nextValue <= this.getRelayMaxDuration
    },
    canAddShiftEndMinute () {
      const nextValue = this.shiftFullDuration + 0.25
      return nextValue <= this.getRelayMaxDuration
    },
    dateMax () {
      return this.$moment().add(60, 'days').toISOString()
    },
    dateMin () {
      const sameDay = !this.isPeriod && this.$moment().isSame(this.startPeriod, 'day')
      if (this.isPeriod || !sameDay) {
        const now = this.$moment()
        now
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        return now.toISOString()
      }
      return this.minStartDate.toISOString()
    },
    endDateMax () {
      return this.$moment(this.startDate)
        .add(60, 'days')
        .add(this.shiftHoursMax, 'hours')
        .add(this.lunchBreak || 0, 'minutes')
        .toISOString()
    },
    endTimeMax () {
      return this.$moment(this.startDate)
        .add(this.shiftHoursMax, 'hours')
        .add(this.lunchBreak || 0, 'minutes')
        .toISOString()
    },
    endDateMin () {
      return this.$moment(this.startDate)
        .add(this.shiftHoursMin, 'hours')
        .add(this.lunchBreak || 0, 'minutes')
        .toISOString()
    },
  },
  watch: {
    'startDateConfig': {
      deep: true,
      handler (val) {
        this.dateModeSelected = ['single', 'range'].includes(val.mode)
      }
    },
    isPeriod (val) {
      this.updateMinDate(val)
    },
    startDate (val) {
      this.updateEndDate()
    },
    endDate () {
      this.updateShiftHours()
    },
    shiftHours (val) {
      if (this.shiftWatcherDisabled) {
        return
      }
      this.updateEndDate()
    },
    lunchBreak (val) {
      this.updateEndDate()
    },
  },
  created () {
    this.shiftHoursMin = this.getRelayMinDuration
    this.shiftHoursMax = this.getRelayMaxDuration
    this.lunchBreakMin = this.getLunchMinValue
    this.lunchBreakMax = this.getLunchMaxValue
  },
  mounted () {
    this.$nextTick(() => {
      // this.$refs.offerPeriod.fp.config.minDate = this.$moment().toISOString()
    })
  },
  methods: {
    onVaccineRequirementUpdate () {
      this.$emit('update:vaccineRequirement', this.vaccineRequirement)
    },
    updateMinDate (value) {
      if (value) {
        const now = this.$moment()
        now
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .add(1, 'days')
        this.$refs.offerPeriod.fp.config.minDate = now.toISOString()
        const endOfMonth = this.$moment().clone().endOf('month')
        const nextMonth = endOfMonth.clone().add(1, 'days')
        this.$refs.offerPeriod.fp.config.maxDate = nextMonth.endOf('month').toISOString()
      } else {
        this.$refs.offerPeriod.fp.config.minDate = this.$moment().toISOString()
      }
    },
    onCalendarModeChange ($event) {
      this.$emit('update:isPeriod', $event === 'range')
      // this.$emit('update:startPeriod', null)
      // this.$emit('update:endPeriod', null)
      if (!this.isPeriod && !this.startPeriod) {
        const startDate = this.$moment(this.minStartDate).format(OFFER_DATE_FORMAT)
        this.$emit('onDateChange', this.minStartDate)
        this.$emit('update:startPeriod', startDate)
      }
      this.dateRange = []
      // this.startDateConfig.mode = $event ? 'range' : 'single'
    },
    changeDateFilter () {
      if (this.dateModeSelected) {
        this.$nextTick(() => {
          this.$refs.offerPeriod.fp.config.minDate = this.$moment().toISOString()
          this.$refs.offerPeriod.fp.open()
        })
      }
    },
    getStartDateWithMinutes () {
      const now = this.$moment()
      const minutes = now.minutes()
      const next = minutes - (minutes % 15) + 60

      return now
        .set({ hour: now.hour(), minute: 0, second: 0, millisecond: 0 })
        .add(next, 'minutes')
    },
    updateCalendar ($event) {
      // this.$refs.offerPeriod.fp.open()
      let startDateObj
      if (!this.isPeriod) {
        startDateObj = this.$moment($event[0])
        const startDate = this.$moment($event[0]).format(OFFER_DATE_FORMAT)
        this.$emit('onDateChange', startDateObj)

        // if (this.$moment().isSame(startDate, 'day')) {
        //   // this.$emit('update:startDate', this.getStartDateWithMinutes().toISOString())
        // } else {
        // }
        this.$emit('update:startPeriod', startDate)
      } else if (Array.isArray($event) && $event.length > 1) {
        startDateObj = this.$moment($event[0])
        const startDate = this.$moment($event[0]).format(OFFER_DATE_FORMAT)
        const endDate = this.$moment($event[1]).format(OFFER_DATE_FORMAT)
        this.$emit('onDateChange', startDateObj)

        const days = this.$moment($event[1]).diff(this.$moment($event[0]), 'days')
        this.$emit('update:dayDiff', days + 1)
        this.$emit('update:startPeriod', startDate)
        this.$emit('update:endPeriod', endDate)
      }
    },
    updateEndDate () {
      const endDate = this.$moment(this.startDate)
        .add(this.shiftHoursFloat, 'hours')
        .add(this.lunchBreak, 'minutes')
        .toISOString()

      this.$emit('update:endDate', endDate)
    },

    updateShiftHours () {
      this.shiftWatcherDisabled = true
      const shiftHours = this.$moment(this.$moment(this.endDate)
        .subtract(this.lunchBreak !== '' ? this.lunchBreak : 0, 'minutes')
        .diff(this.$moment(this.startDate)))
        // .subtract(3, 'hours')
        .tz('UTC')
        .format('H:mm')
      this.$emit('update:shiftHours', shiftHours)
      this.$nextTick(() => {
        this.shiftWatcherDisabled = false
      })
    },
    onDisableAlertData () {
      this.enableAlertData = false
      this.alertData = {
        message: undefined,
        actionName: undefined,
        callback: undefined,
      }
    },
    onEnableAlertData () {
      this.enableAlertData = true

      this.alertData = {
        message: 'В вашей организации используется автоматическое назначение перерывов в ' +
          'зависимости от длительности смены. Если есть вопросы обратитесь в поддержку',
        actionName: 'Обратитесь в поддержку',
        callback: () => {
          this.onDisableAlertData()
          this.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
        }
      }
    },

    onShiftEndHourUpdated ($event, action) {
      if (this.canAddShiftEndHour || action === 'decrease') {
        this.$emit('update:endDate', $event)
      }
    },
    onShiftEndMinutesUpdated ($event, action) {
      if (this.canAddShiftEndMinute || action === 'decrease') {
        this.$emit('update:endDate', $event)
      }
    },
    onShiftHoursUpdated ($event, action) {
      if (this.canAddShiftDurationHour || action === 'decrease') {
        this.$emit('update:shiftHours', $event)
      }
    },
    onBreakValueUpdated ($event, action) {
      if (!this.canAddLunchBreak && action === 'increase') {
        return
      }
      if (!this.hasLunchBreakSettingMatrix) {
        this.$emit('update:lunchBreak', $event)
      } else {
        this.onEnableAlertData()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/create-offer-modal'
</style>

<style lang="sass">
.unselected_date_mode
  color: red !important
.unselected_date_wrapper_mode
  visibility: hidden
.has-error
  border-color: red !important
.radio-input-error
  border: 1px solid red !important
.radio-label-error
  color: red

.radio-group
  display: flex
  margin-top: 20px

.period-wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-height: 40px
  border: 1px solid grey
  border-radius: 12px
  padding: 8px
  min-width: 180px

.date-tabs
  display: flex
  padding-left: 25px
  padding-bottom: 15px
  margin-bottom: 15px
  font-weight: 600
  font-size: 16px
  line-height: 20px
  color: black

  &__tab
    position: relative
    opacity: 0.4
    cursor: pointer
    margin-right: 20px

    &--active
      opacity: 1
.selector
  .input-date-wide
    width: 45px
</style>
