<template>
  <v-card
    class="mx-auto"
  >
    <v-toolbar>
      <v-toolbar-title>Работники моей Команды</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="error"
        icon
        @click="onClose"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <div
      v-if="loading || executorListEmpty"
      style="height: 256px; width: 256px; margin: 0 auto"
    >
      <v-progress-linear
        v-if="loading"
        style="margin-top: 200px"
        color="success"
        indeterminate
        rounded
        height="6"
      />
      <v-alert
        v-if="!loading && executorListEmpty"
        outlined
        style="margin-top: 100px"
        color="error"
        class="py-6 px-12"
        :icon="undefined"
      >
        <p class="text-center">
          Для вашего объекта список исполнителей пусть
        </p>
      </v-alert>
    </div>
    <OfferExecutorViewMinCard
      v-for="(item) in modelList"
      v-else
      :key="item.id"
      :executor="item"
    />
  </v-card>
</template>
<script>
import OfferExecutorViewMinCard from '@/components/offer/offer-executor-min-card'

export default {
  name: 'WbdOfferExecutorList',
  components: { OfferExecutorViewMinCard },
  data: () => ({
    loading: true,
    modelList: true,
    executorListEmpty: false,
  }),
  async created () {
    await this.requestWorkerList()
  },
  methods: {
    onClose () {
      this.$emit('executorListModalClose')
    },
    async requestWorkerList () {
      this.modelList = []
      this.loading = true
      this.executorListEmpty = false
      try {
        const response = await this.$store.dispatch(
          'office/getFavoriteLaborerList',
          { payload: { source: 'offer' } })
        setTimeout(() => {
          this.loading = false
        }, 500)
        const responseData = response.data
        if (responseData) {
          if (responseData.length) {
            this.modelList = responseData.map((item) => (
              {
                ...item,
              }
            ))
          } else {
            this.executorListEmpty = true
          }
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
  }
}
</script>
