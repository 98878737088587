<template>
  <v-container>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="py-0 my-0"
      >
        <OfferExecutorCategory @onExecutorCategoryChange="onExecutorCategoryChange" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="py-0 my-0"
      >
        <v-subheader>
          {{ 'Тип оффера' }}:
        </v-subheader>
        <v-select
          v-model="selectedOption"
          class="mt-2"
          :items="offerExecutorAssignmentType"
          item-value="id"
          item-text="title"
          outlined
          color="success"
          rounded
          return-object
          :disabled="selectDisabled"
          :error="!form.executorAssignmentType"
          dense
          :hint="'Сначала выберите тип сотрудников'"
          :persistent-hint="!hasExecutorCategory"
          placeholder="Тип оффера"
          required
          @change="onSelectedOptionChanged"
        />
      </v-col>
    </v-row>
    <v-subheader v-if="getDescription">
      {{ getDescription }}
    </v-subheader>
    <v-card-actions v-if="hasFavoriteExecutorAccess">
      <v-btn
        text
        outlined
        color="success"
        rounded
        @click="enableExecutorModal"
      >
        <span style="text-transform: none">
          {{ `Работники моей Команды (${getOfficeFavoriteWorkers})` }}
        </span>
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="hasRemoteExecutorAccess">
      <v-btn
        text
        outlined
        color="success"
        disabled
        rounded
        @click="enableExecutorModal"
      >
        <span style="text-transform: none">
          {{ `Свои исполнители (${orgPrivateStaffCounter})` }}
        </span>
        <!--        <v-icon>mdi-eye</v-icon>-->
      </v-btn>
    </v-card-actions>
    <OfferExecutorView
      v-if="hasPrivateAccess"
      :is-private-category="isPrivateExecutorCategory"
      @offerFormExecutorChanged="onOfferExecutorChange"
    />
    <OfferExecutorListModal
      v-if="executorModalEnabled"
      :enabled="executorModalEnabled"
      @executorListModalClose="disableExecutorModal"
    />
    <v-alert
      v-if="alertData && !!alertData.message"
      prominent
      type="error"
    >
      <v-row align="center">
        <v-col class="grow">
          {{
            alertData.message
          }}
        </v-col>
        <v-col
          class="shrink"
          @click="alertData.callback"
        >
          <v-btn
            rounded
            outlined
            color="success"
          >
            {{ alertData.actionName }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>

import routerList from '@/router/labels'
import OfferExecutorCategory from '@/components/offer/offer-executor-category'

export const EXECUTOR_CATEGORY_PUBLIC = 'executor-category-public'
export const EXECUTOR_CATEGORY_PRIVATE = 'executor-category-private'
export const VACANCY_TYPE_INNER_WORKER = 'private-worker'
export const VACANCY_TYPE_SELF_EMPLOYED = 'self-employed-worker'
export const VACANCY_TYPE_PUBLIC = 'public'
export const VACANCY_TYPE_NONE = 'none'

export const ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL = 'manual'
export const ALIAS_VACANCY_TYPE_INNER_WORKER = 'private-worker'
export const ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO = 'auto'
// export const ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_SYSTEM = 'system';
export const ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST = 'favorite-list'
export const ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE = 'private'

export const TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL = 'Обычный оффер - Запишется на смену через ваше приглашение'
export const TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO =
  'Оффер с автозаписью - Запишется первый откликнувшийся исполнитель'
export const TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST =
  'Для моей команды - Увидит только ваша команда с записью в 1 клик'
export const TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE =
  'Персональный - Для конкретного исполнителя, запишется в 1 клик'
export const TYPE_INNER_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE = 'Для конкретного сотрудника вашей компании'
export const TYPE_VACANCY_TYPE_INNER_WORKER = 'Для всех сотрудников вашей компании'

// export const TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_SYSTEM = 'Система';
// export const TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_CUSTOM = 'Кастомный';

// all offers where is favorite is not true and is_team_access_level is null and executor id is null
// Публичный (видят все)
export const ALIAS_OEC_LABORER = 'oec-laborer'
// all offers where offer_institution_staff is not empty <------> Мои исполнители (Удаленный персонал)
export const ALIAS_OEC_REMOTE_STAFF = 'oec-remote-staff'
// all offers with executor id
export const ALIAS_OEC_PERSONAL = 'oec-personal'
// all offers where is favorite is true and is_team_access_level is null and executor id is null
// Моя команда/избранных
export const ALIAS_OEC_FAVORITE = 'oec-favorite'


import OfferExecutorListModal from '@/components/offer/executor-list-modal'
import OfferExecutorView from '@/components/offer/offer-executor-view'

export default {
  name: 'OfferTypeView',
  components: {
    OfferExecutorCategory,
    OfferExecutorView,
    OfferExecutorListModal,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    isPrivate: {
      type: Boolean,
    },
    onOfferTypeChange: {
      type: Function,
      required: false,
    },
  },
  data: function () {
    return {
      alertData: {
        message: undefined,
        actionName: undefined,
        callback: undefined,
      },
      selectedOption: null,
      executor: {
        rating: null,
        trustability: '',
        fullName: null,
        age: null,
        workExperience: null,
        avatar: { }
      },
      executorModalEnabled: false,
      executorListSize: 999,
      searchQuery: '',
      allowedOfferType: undefined,
      officeHasNoVacancy: undefined,
    }
  },
  computed: {
    selectDisabled () {
      return !this.hasExecutorCategory || this.disabled
      // (this.form.vacancySelected && !!this.form.vacancySelected.offerAccessType)
    },
    hasExecutorCategory () {
      const { executorCategory } = this.form
      return !!executorCategory && [
        EXECUTOR_CATEGORY_PUBLIC,
        EXECUTOR_CATEGORY_PRIVATE,
      ].includes(executorCategory)
    },
    isPrivateExecutorCategory () {
      if (!this.hasExecutorCategory) {
        return false
      }
      const { executorCategory } = this.form
      return !!executorCategory && executorCategory === EXECUTOR_CATEGORY_PRIVATE
    },
    isPublicExecutorCategory () {
      if (!this.hasExecutorCategory) {
        return false
      }
      const { executorCategory } = this.form
      return !!executorCategory && executorCategory === EXECUTOR_CATEGORY_PUBLIC
    },
    // no vacancy at all
    // only private vacancy
    // only public vacancy
    // not remote executor
    // not favorite executor

    noVacancyErrorMessage () {
      const self = this
      return {
        message: 'У вас нет активированных вакансий, создайте сначала вакансию, прежде чем делать оффер',
        actionName: 'К вакансиям',
        callback: () => {
          this.onDisableAlertData()
          self.$router.push({ name: routerList.VACANCIES_LIST_NAME })
        }
      }
    },
    noPublicVacancyErrorMessage () {
      const self = this

      self.alertData = {
        message: 'У вас нет публичных вакансий, создайте сначала вакансию, прежде чем делать публичный оффер',
        actionName: 'К вакансиям',
        callback: () => {
          this.onDisableAlertData()
          self.$router.push({ name: routerList.VACANCIES_LIST_NAME })
        }
      }
      return self.alertData
    },
    noPrivateVacancyErrorMessage () {
      const self = this

      self.alertData = {
        message: 'У вас нет вакансий для своих, создайте сначала вакансию, прежде чем делать оффер для своих',
        actionName: 'К вакансиям',
        callback: () => {
          this.onDisableAlertData()
          self.$router.push({ name: routerList.VACANCIES_LIST_NAME })
        }
      }
      return self.alertData
    },
    noPrivateExecutorErrorMessage () {
      const self = this

      self.alertData = {
        message: 'У вас нет персональных исполнителей Обратитесь в поддержку сервиса или к своему аккаунт менеджеру',
        actionName: 'К поддержке',
        callback: () => {
          this.onDisableAlertData()
          self.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
        }
      }
      return self.alertData
    },
    noFavoriteExecutorError () {
      const self = this

      self.alertData = {
        message: 'У вас нет избранных исполнителей. Обратитесь в поддержку сервиса или к своему аккаунт менеджеру',
        actionName: 'К поддержке',
        callback: () => {
          this.onDisableAlertData()
          self.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
        }
      }
      return self.alertData
    },
    noWorkerTypeError () {
      const self = this

      self.alertData = {
        message: 'У вас не включен соответствующий функционал в системе. ' +
          'Обратитесь в поддержку сервиса или к своему аккаунт менеджеру',
        actionName: 'К поддержке',
        callback: () => {
          this.onDisableAlertData()
          self.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
        }
      }
      return self.alertData
    },

    publicOfferExecutorAssignmentType () {
      return [
        {
          'id': ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          'title': TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          'desc': 'На данный оффер смогут записаться “в один клик” любой исполнитель. '
        },
        {
          'id': ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL,
          'title': TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL,
        },
        {
          'id': ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
          'title': TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
          'desc': 'Данный оффер будет отправлен конкретному пользователю. Вы можете его найти по номеру телефона.'
        },
        {
          'id': ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST,
          'title': TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST,
          'desc': 'Данный оффер будет опубликован только для исполнителей из списка Избранных.'
        },
      ]
    },
    privateOfferExecutorAssignmentType () {
      return [
        {
          'id': ALIAS_VACANCY_TYPE_INNER_WORKER,
          'title': TYPE_VACANCY_TYPE_INNER_WORKER,
          'desc': 'Данный оффер будет опубликован только для исполнителей из списка для своих.'
        },
        {
          'id': ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
          'title': TYPE_INNER_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
          'desc': 'Данный оффер будет отправлен конкретному пользователю. Вы можете его найти по номеру телефона.'
        },
      ]
    },
    offerExecutorAssignmentType () {
      if (!this.hasExecutorCategory) {
        return []
      } else if (this.isPrivateExecutorCategory) {
        return this.privateOfferExecutorAssignmentType
      }
      return this.publicOfferExecutorAssignmentType
    },


    getDescription () {
      if (!this.selectedOption || !this.selectedOption.desc) {
        return ''
      }

      return this.selectedOption.desc.trim()
    },
    hasAccessType () {
      return !!this.selectedOption && !!this.selectedOption.id
    },
    getAccessTypeId () {
      if (!this.hasAccessType) {
        return null
      }
      return this.selectedOption.id
    },
    hasPrivateAccess () {
      return !!this.hasAccessType && this.getAccessTypeId === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE
    },
    hasFavoriteExecutorAccess () {
      return !!this.hasAccessType && this.getAccessTypeId === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST
    },
    hasRemoteExecutorAccess () {
      return !!this.hasAccessType && this.getAccessTypeId === ALIAS_VACANCY_TYPE_INNER_WORKER
    },
    hasManualAssignment () {
      return !!this.hasAccessType && this.getAccessTypeId === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL
    },
    hasAutoAssignment () {
      return !!this.hasAccessType && this.getAccessTypeId === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO
    },
  },
  watch: {
    isPrivate: function (val) {
      // if (val) {
      //   this.form.executorAssignmentType = {
      //     'id': ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
      //     'title': TYPE_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
      //     'desc': 'На данный оффер смогут записаться “в один клик” любой исполнитель. '
      //   }
      // }
    }
  },
  async created () {
    this.form.offerAccessType = null

    const orgTotalVacancyNumber = this.getOrgTotalVacancyNumber
    const publicVacancyNumber = this.getOrgPublicVacancyNumber
    const privateVacancyNumber = this.getOrgPrivateVacancyNumber

    if (orgTotalVacancyNumber < 1) {
      this.form.offerAccessType = VACANCY_TYPE_PUBLIC
    } else if (publicVacancyNumber > 0 && privateVacancyNumber < 1) {
      this.form.offerAccessType = VACANCY_TYPE_INNER_WORKER
    } else if (orgTotalVacancyNumber > 0 && privateVacancyNumber < 1) {
      this.form.offerAccessType = VACANCY_TYPE_PUBLIC
    } else if (publicVacancyNumber > 0 && privateVacancyNumber > 0 &&
      (!this.organizationHasWorkerType || !this.orgPrivateStaffCounter)) {
      this.form.offerAccessType = VACANCY_TYPE_PUBLIC
    } else if (publicVacancyNumber > 0 && privateVacancyNumber > 0 &&
      (!!this.organizationHasWorkerType && this.orgPrivateStaffCounter > 0)) {
      this.form.offerAccessType = null
    }
  },
  methods: {
    getOfferAccessSettings (selectedOption) {
      switch (selectedOption) {
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL,
          executorCategoryType: ALIAS_OEC_LABORER
        }
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorCategoryType: ALIAS_OEC_PERSONAL
        }
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorCategoryType: ALIAS_OEC_FAVORITE
        }
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorCategoryType: ALIAS_OEC_LABORER
        }
      case ALIAS_VACANCY_TYPE_INNER_WORKER:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorCategoryType: ALIAS_OEC_REMOTE_STAFF
        }
      }
    },
    onSelectedOptionChanged ($event) {
      this.onDisableAlertData()
      const error = this.getVacancyTypeError()
      if (!error) {
        this.onOfferTypeChange($event)
      } else {
        this.alertData = error
        this.onOfferTypeChange(null)
      }

      this.form.executor = null
    },
    getTypeError (val) {
      if (!val) {
        return
      }
      if (!this.getOrgTotalVacancyNumber) {
        return this.noVacancyErrorMessage
      }

      if (val === EXECUTOR_CATEGORY_PRIVATE) {
        if (!this.organizationHasWorkerType) {
          return this.noWorkerTypeError
        }
        if (!this.getOrgPrivateVacancyNumber) {
          return this.noPrivateVacancyErrorMessage
        }
        if (!this.orgPrivateStaffCounter) {
          return this.noPrivateExecutorErrorMessage
        }
      } else {
        if (!this.getOrgPublicVacancyNumber) {
          return this.noPublicVacancyErrorMessage
        }
      }
    },
    getVacancyTypeError () {
      if (!this.selectedOption || !this.selectedOption.id) {
        return
      }
      if (!this.getOrgTotalVacancyNumber) {
        return this.noVacancyErrorMessage
      }
      switch (this.selectedOption.id) {
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL:
        if (!this.getOrgPublicVacancyNumber) {
          return this.noPublicVacancyErrorMessage
        }
        return null
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE:
        return null
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST:
        if (!this.getOrgPublicVacancyNumber) {
          return this.noPublicVacancyErrorMessage
        }
        if (!this.getOfficeFavoriteWorkers) {
          return this.noFavoriteExecutorError
        }
        return null
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO:
        if (!this.getOrgPublicVacancyNumber) {
          return this.noPublicVacancyErrorMessage
        }
        return null
      case ALIAS_VACANCY_TYPE_INNER_WORKER:
        if (!this.organizationHasWorkerType) {
          return this.noWorkerTypeError
        }
        if (!this.getOrgPrivateVacancyNumber) {
          return this.noPrivateVacancyErrorMessage
        }
        if (!this.orgPrivateStaffCounter) {
          return this.noPrivateExecutorErrorMessage
        }
        return null
      }

      return null
    },
    enableExecutorModal () {
      this.executorModalEnabled = true
    },
    disableExecutorModal () {
      this.executorModalEnabled = false
    },
    onOfferExecutorChange (executor) {
      // this.executorModalEnabled = false
      this.form.executor = executor
    },
    onDisableAlertData () {
      this.alertData = null
    },
    onExecutorCategoryChange (val) {
      this.selectedOption = null
      this.onSelectedOptionChanged(null)
      const error = this.getTypeError(val)
      if (!error) {
        this.form.executorCategory = val
        this.$emit('onFormExecutorCategoryChange', val)
      } else {
        this.alertData = error
        this.form.executorCategory = null
        this.$emit('onFormExecutorCategoryChange', null)
      }
      // 79653732012
      this.form.executor = null
      this.form.executor = null
      this.form.vacancySelected = null
      this.form.laborerNumber = null
      this.eventsBus.$emit(this.eventNames.ON_OFFER_EXECUTOR_CLEARED)
    },
  }
}
</script>

<style type="text/scss" scoped>
.offer-type-desc {
  color: #9B9B9B;
}

</style>
