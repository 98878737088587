<template>
  <div
    class="selector"
  >
    <AppButton
      class="selector__button-up custom-btn-wrapper"
      :class="{'disabled': increaseBtnDisabled || disableIncrease, 'view_disabled': disabled}"
      @click.native="increase"
    >
      <IconDropdown is-open />
    </AppButton>
    <div
      class="selector__button-input"
      :class="{'input-date-invalid': invalid}"
    >
      {{ displayedValue }} <slot />
    </div>
    <AppButton
      class="selector__button-down custom-btn-wrapper"
      :class="{'disabled': decreaseBtnDisabled, 'view_disabled': disabled}"
      @click.native="decrease"
    >
      <IconDropdown />
    </AppButton>
  </div>
</template>

<script>
import IconDropdown from '@/components/common/icons/IconDropdown'
import AppButton from '@/components/common/simple/AppButton'
export default {
  name: 'AppTimeSelector',
  components: { IconDropdown, AppButton },
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    max: {
      type: [String, Boolean, Number],
      default: false
    },
    min: {
      type: [String, Boolean, Number],
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: 'minutes'
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disableIncrease: {
      type: Boolean,
    },
    minStartDate: {
      type: Object,
    }
  },
  computed: {
    increaseBtnDisabled () {
      if (this.max === false) {
        return false
      }

      if (this.type === 'number') {
        return this.max === +this.value
      }
      if (this.type === 'time') {
        const [hours] = this.value.split(':')
        return parseInt(hours) === this.max
      }
      return this.$moment(this.max).isBefore(this.$moment(this.value).add(this.step, this.type))
    },
    decreaseBtnDisabled () {
      if (this.min === false) {
        return false
      }

      if (this.type === 'number') {
        return this.min === +this.value
      }

      if (this.type === 'time') {
        const [hours] = this.value.split(':')
        return parseInt(hours) === this.min
      }

      if (this.minStartDate) {
        const next = this.$moment(this.value).subtract(this.step, this.type)
        return next.isBefore(this.minStartDate)
      }

      return this.$moment(this.min).isAfter(this.$moment(this.value).subtract(this.step, this.type))
    },
    displayedValue () {
      if (this.type === 'minutes') {
        return this.$moment(this.value).format('mm')
      }

      if (this.type === 'hours') {
        return this.$moment(this.value).format('HH')
      }

      if (this.type === 'number') {
        return this.value
      }

      return this.value
    },
  },
  methods: {
    increase () {
      if (this.disabled || this.increaseBtnDisabled) {
        // чтобы показать алерт
        this.$emit('input', null)
        this.$emit('increase', null)
        return
      }
      if (this.type === 'number') {
        if (this.value === '') {
          this.$emit('input', 0)
          this.$emit('increase', 0)
        } else {
          this.$emit('input', this.value + this.step)
          this.$emit('increase', this.value + this.step)
        }
        return
      }
      if (this.type === 'time') {
        const [hours, minutes] = this.value.split(':')
        this.$emit('input', `${parseInt(hours) + this.step}:${minutes}`)
        this.$emit('increase', `${parseInt(hours) + this.step}:${minutes}`)
        return
      }

      if (this.minStartDate) {
        const next = this.$moment(this.value).add(this.step, this.type)
        const isSameDay = next.isSame(this.minStartDate, 'day')

        if (next.isBefore(this.minStartDate) || !isSameDay) {
          this.$emit('input', this.$moment(this.minStartDate).toISOString())
          this.$emit('increase', this.$moment(this.minStartDate).toISOString())
          return
        }
      }
      this.$emit('input', this.$moment(this.value).add(this.step, this.type).toISOString())
      this.$emit('increase', this.$moment(this.value).add(this.step, this.type).toISOString())
    },
    decrease () {
      if (this.disabled || this.decreaseBtnDisabled) {
        this.$emit('input', null)
        this.$emit('decrease', null)
        return
      }
      if (this.type === 'number') {
        this.$emit('input', +this.value - this.step)
        this.$emit('decrease', +this.value - this.step)
        return
      }
      if (this.type === 'time') {
        const [hours, minutes] = this.value.split(':')
        if (hours <= this.min) {
          this.$emit('input', `${this.min}:00`)
          this.$emit('decrease', `${this.min}:00`)
        } else {
          this.$emit('input', `${parseInt(hours) - this.step}:${minutes}`)
          this.$emit('decrease', `${parseInt(hours) - this.step}:${minutes}`)
        }
        return
      }
      const next = this.$moment(this.value).subtract(this.step, this.type)

      if (this.decreaseBtnDisabled) {
        // todo show error
      } else {
        this.$emit('input', next.toISOString())
        this.$emit('decrease', next.toISOString())
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .selector
    display: flex
    flex-direction: column
    width: 158px
    user-select: none
    font-size: 12px
    line-height: 15px
    font-weight: 800
    width: 40px

    &-number
      width: 40px

    &__button-up, &__button-down
      display: flex
      justify-content: center
      text-align: center
      width: 100%
      height: 29px
      border: 1px solid #F5F5F5
      cursor: pointer

      &.disabled
        cursor: auto
        pointer-events: none

      &.view_disabled
        cursor: auto
        opacity: 0.3

      &:hover svg
        fill: #7FC012

      &.disabled svg
        fill: rgba(0, 0, 0, 0.2)

      svg
        margin-top: 10px

    &__button-up
      border-radius: 10px 10px 0 0
    &__button-down
      border-radius: 0 0 10px 10px

    &__button-input
      font-weight: bold
      display: flex
      align-items: center
      justify-content: center
      background: #F0F0F1
      box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.1)
      text-align: center
      user-select: none
      height: 40px
      padding: 0 3px
    .input-date-invalid
        background-color: rgba(255, 0, 0, 0.2)
  .offer__selector
    width: 100%
</style>
