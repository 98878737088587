<template>
  <v-row justify="center">
    <v-dialog
      v-model="enabled"
      persistent
      max-width="600px"
    >
      <WbdOfferExecutorList @executorListModalClose="onClose" />
    </v-dialog>
  </v-row>
</template>
<script>
import WbdOfferExecutorList from '@/components/offer/executor-list'
export default {
  name: 'OfferExecutorListModal',
  components: { WbdOfferExecutorList },
  props: {
    enabled: {
      type: Boolean
    },
  },
  data: () => ({
  }),
  methods: {
    onClose () {
      this.$emit('executorListModalClose')
    }
  }
}
</script>
