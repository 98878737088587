<template>
  <v-radio-group
    v-model="executorCategory"
    row
    style="width: 100%"
    @change="onExecutorCategoryChange"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        :md="6"
        :sm="12"
        class="py-0 my-0 py-sm-2"
      >
        <v-radio
          :label="dataCollection[0].label"
          :value="dataCollection[0].id"
          name="offer-executor-category-option"
          class="elevation-2 py-4 px-4 offer-executor-category-option"
          :class="{'offer-executor-category-error': !executorCategory}"
          :color="executorCategory ? 'success' : 'error'"
        >
          <template v-slot:label>
            <div :class="{'offer-executor-category-label-error': !executorCategory}">
              {{ dataCollection[0].label }}
            </div>
          </template>
        </v-radio>
      </v-col>
      <v-col
        cols="12"
        :md="6"
        :sm="12"
        class="py-0 my-0 py-sm-2"
      >
        <v-radio
          :color="executorCategory ? 'success' : 'error'"
          class="elevation-2 py-4 px-4 offer-executor-category-option"
          name="offer-executor-category-option"
          :class="{'offer-executor-category-error': !executorCategory}"
          :label="dataCollection[1].label"
          :value="dataCollection[1].id"
        >
          <template v-slot:label>
            <div :class="{'offer-executor-category-label-error': !executorCategory}">
              {{ dataCollection[1].label }}
            </div>
          </template>
        </v-radio>
      </v-col>
    </v-row>
  </v-radio-group>
</template>

<script>

import { EXECUTOR_CATEGORY_PRIVATE, EXECUTOR_CATEGORY_PUBLIC } from '@/components/offer/offer-type-view'

export default {
  name: 'OfferExecutorCategory',
  props: {
    executor: {
      type: Object,
    },
    executorNotFound: {
      type: Boolean,
    },
    isBusy: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      executorCategory: null,
      dataCollection: [
        {
          id: EXECUTOR_CATEGORY_PUBLIC,
          label: 'Для внешних работников'
        },
        {
          id: EXECUTOR_CATEGORY_PRIVATE,
          label: 'Для сотрудников вашей компании'
        },
      ]
    }
  },
  computed: {
  },
  async created () {

  },
  methods: {
    onExecutorCategoryChange ($vent) {
      this.$emit('onExecutorCategoryChange', $vent)
    }
  }
}
</script>

<style type="text/scss" scoped>
.offer-executor-category-option {
  border-radius: 8px;
  /*min-width: 380px;*/
  /*max-width: 380px;*/
}
.offer-executor-category-error {
  border: thin solid red
}
.offer-executor-category-label-error {
  color: red
}

</style>
