import { render, staticRenderFns } from "./IconMinus.vue?vue&type=template&id=974a0f9a&scoped=true"
import script from "./IconMinus.vue?vue&type=script&lang=js"
export * from "./IconMinus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "974a0f9a",
  null
  
)

export default component.exports