<template>
  <v-card
    class="mb-2"
    :flat="executorNotFound"
  >
    <div
      v-if="executor && executor.activeRelay"
      class="floating-alert"
    >
      <div class="description">
        в это время На другой смене
      </div>
    </div>
    <div
      v-if="executor && executor.isBlackListed"
      class="floating-alert"
    >
      <div class="description">
        Этот пользователь заблокирован. Обратитесь в поддержку сервиса
      </div>
    </div>
    <v-list-item>
      <v-list-item-avatar v-if="executor && executor.avatar && executor.avatar.path">
        <v-img :src="executor.avatar.path" />
      </v-list-item-avatar>
      <v-list-item-content v-if="executor">
        <v-list-item-subtitle>
          <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
            <span>
              <v-icon
                color="success"
                small
              >mdi-star</v-icon>
              <span style="font-size: 0.8em">{{ executor.rating ? executor.rating.toFixed(1) : 0 }}</span>
            </span>
            <v-divider
              vertical
              class="mx-2"
            />

            <span v-if="getTrustabilityLabel">{{ `Надежность: ${getTrustabilityLabel}` }}</span>
          </div>
        </v-list-item-subtitle>
        <v-list-item-title>{{ getExecutorFullNameLabel }}</v-list-item-title>
        <v-list-item-subtitle>{{ `Опыт по профессии: ${executor.experienceHourNumber || 0} ч` }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-if="executorNotFound">
        <v-alert
          outlined
          color="error"
          class="py-6 px-12"
          :icon="undefined"
        >
          <p class="text-center">
            По данному телефону
            пользователь не найден
          </p>
        </v-alert>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>

export default {
  name: 'OfferExecutorViewMinCard',
  props: {
    executor: {
      type: Object,
    },
    executorNotFound: {
      type: Boolean,
    },
    isBusy: {
      type: Boolean,
    },
  },
  data: function () {
    return {
    }
  },
  computed: {
    getTrustabilityLabel () {
      if (this.executor.trustability === 'Сверхнадёжный') {
        return this.executor.trustability
      }

      return null
    },
    getExecutorFullNameLabel () {
      if (!this.executor || !this.executor.fullName) {
        return 'Не указано'
      }

      const { fullName, age } = this.executor
      let ageLabel = ''
      if (age) {
        ageLabel = `, ${age}`
      }

      return `${fullName}${ageLabel}`
    },
  },
  async created () {

  },
  methods: {
  }
}
</script>
<style type="text/scss">
.floating-alert{
  position: absolute;
  background-color: #FF553E;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  max-height: 26px;
  text-align: center;
  z-index: 3;
  .description {
    color: whitesmoke;
    width: 90%;
    margin: 0 auto;
  }
}

</style>
