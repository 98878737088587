<template>
  <div>
    <OfferTypeVieew
      :form.sync="form"
      :on-offer-type-change="onOfferTypeChange"
      :disabled="selectedVacancyIsPrivate"
      :isPrivate="selectedVacancyIsPrivate"
    />
    <v-divider
      v-if="form.executorAssignmentType"
      class="my-6"
    />
    <v-row
      v-if="form.executorAssignmentType"
      no-gutters
    >
      <v-col
        cols="12"

        :sm="12"
        :md="6"
        :lg="6"
        class="py-0 my-0"
        style="min-width: 180px"
      >
        <v-subheader>
          <v-list-item-subtitle>
            {{ `Вакансия` }}
          </v-list-item-subtitle>
        </v-subheader>
        <v-autocomplete
          v-model="form.vacancySelected"
          style="max-width: 380px"
          :items="vacancyList"
          dense
          :disabled="!form.executorAssignmentType"
          rounded
          :color="'#8BD118'"
          outlined
          :error="!form.vacancySelected || !form.vacancySelected.id"
          :search-input.sync="searchQuery"
          return-object
          :loading="false"
          background-color="#F0F0F1"
          item-value="id"
          item-text="title"
          :placeholder="getPlaceHolder"
          :multiple="false"
          class="offer-modal__select mb-5"
          flat
          hide-no-data
          hide-details="auto"
          @change="$emit('update:form', form)"
          @input="onVacancyInput"
          @focus="onVacancyInput"
        />
      </v-col>
      <v-col
        cols="12"

        :sm="12"
        :md="6"
        :lg="6"
      >
        <v-subheader>
          <v-list-item-subtitle>
            {{ `${$t('offer.creation.hourly_rate')} (на руки)` }}
          </v-list-item-subtitle>
        </v-subheader>
        <v-card-actions
          class="py-0 my-0"
          style="max-width: 380px !important;"
        >
          <v-text-field
            label="Физлицо"
            :value="form.costPerHour || '-'"
            readonly
            dense
            outlined
            :disabled="!form.executorAssignmentType"
            rounded
            color="success"
            append-outer-icon="mdi-slash-forward"
          />
          <v-text-field
            label="Самозанятый"
            :value="form.selfEmployerCostPerHour || '-'"
            readonly
            outlined
            rounded
            :disabled="!form.executorAssignmentType"
            dense
            color="success"
          />
        </v-card-actions>
      </v-col>
    </v-row>
    <div
      v-if="form.executorAssignmentType"
      class="modal__input-group"
      style="position: relative"
    />
  </div>
</template>

<script>
import OfferTypeVieew, {
  ALIAS_VACANCY_TYPE_INNER_WORKER,
  ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
  EXECUTOR_CATEGORY_PRIVATE, EXECUTOR_CATEGORY_PUBLIC
} from '@/components/offer/offer-type-view'
import routerList from '@/router/labels'
import AppWorkerTypeUtil from '@/utils/worker-type-util'
import AppConfig from '@/utils/settings'

export const VACANCY_TYPE_INNER_WORKER = 'private-worker'
export const VACANCY_TYPE_SELF_EMPLOYED = 'self-employed-worker'
export const VACANCY_TYPE_PUBLIC = 'public'
export const VACANCY_TYPE_NONE = 'none'

export default {
  name: 'OfferVacancySelect',
  components: {
    OfferTypeVieew,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    validationFields: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      searchQuery: '',
      officeHasNoVacancy: undefined,
      vacancyList: [],
      enableAlertData: false,
      alertData: {
        message: undefined,
        actionName: undefined,
        callback: undefined,
      },
    }
  },
  computed: {
    selectedVacancyIsPrivate () {
      return this.form.executorAssignmentType === VACANCY_TYPE_INNER_WORKER
    },
    offerTypeSelectionSet () {
      return [
        { label: 'Для своих', value: VACANCY_TYPE_INNER_WORKER, name: 'private' },
        { label: 'Для всех', value: VACANCY_TYPE_PUBLIC, name: 'public' }
      ]
    },
    showSelfEmployedCost () {
      if (!this.form || !this.form.vacancySelected || !this.form.vacancySelected.isPrivate) {
        return true
      }

      return this.form.vacancySelected.isPrivate &&
        this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_RU_SELF_EMPLOYED
    },
    showLegalWorkerCost () {
      if (!this.form || !this.form.vacancySelected || !this.form.vacancySelected.isPrivate) {
        return true
      }

      return this.form.vacancySelected.isPrivate &&
        this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_LEGAL_PERSON
    },
    hasAllPayment () {
      return !!this.showLegalWorkerCost && !!this.showSelfEmployedCost
    },
    currentUser () {
      return this.$store.state.users.info
    },
    hasWorkerType () {
      if (!this.currentUser) {
        return false
      }

      return !!this.currentUser.organization && !!this.currentUser.organization.workerType
    },
    getWorkerType () {
      if (!this.currentUser) {
        return false
      }

      return this.currentUser.organization.workerType
    },
    vacancyStat () {
      return this.getOrgVacancyStat
    },
    getPrivateVacancyNumber () {
      return this.getOrgPrivateVacancyNumber
    },
    getPublicNumber () {
      return this.getOrgPublicVacancyNumber
    },
    getTotal () {
      return this.getOrgTotalVacancyNumber
    },
    privateStaffCounter () {
      return this.getOrgPrivateVacancyNumber
    },
    getRequestingVacancyList () {
      return this.$store.state.vacancy.requestingVacancyList
    },
    getPlaceHolder () {
      if (this.getRequestingVacancyList) {
        return 'Ожидание вакансии'
      } else if (!this.vacancyList || !this.vacancyList.length) {
        return 'Вакансии не найдены'
      }

      return 'Выберите вакансию'
    },
    hasRegion () {
      if (AppConfig.getVacancyMultiRegion() === '1') {
        return (!!this.currentOffice && this.currentOffice.region && this.currentOffice.region.id)
      }
      return true
    },
    multiRegionEnabled () {
      return AppConfig.getVacancyMultiRegion() === '1'
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    hourlyPayDisabled () {
      const vacancy = this.form.vacancySelected
      if (!vacancy) {
        return true
      }
      if (vacancy && vacancy.regionSetting && vacancy.regionSetting.length) {
        const regionSetting = vacancy.regionSetting[0]
        return regionSetting.hasFixedPay
      }
      return false
    },
  },

  async created () {
  },
  methods: {
    onEnableAlertData () {
      this.enableAlertData = true
    },
    onDisableAlertData () {
      this.enableAlertData = false
    },
    getPrefix (item) {
      if (item.isPrivate) {
        return 'Для_Своих '
      }
      if (item.selfEmployeeOnly) {
        return 'Для_СМЗ '
      }

      return ''
    },
    onVacancyInput () {
      if (this.officeHasNoVacancy) {
        this.onEnableAlertData()
      }
      this.$emit('update:form', this.form)
    },
    async requestVacancyList () {
      try {
        this.$store.commit('vacancy/setRequestingVacancyList', true)
        // TODO region check
        await this.$store.dispatch(
          'vacancy/fetchVacancyList',
          {
            region: this.multiRegionEnabled ? { id: this.currentOffice.region.id } : undefined,
          }
        )
        this.$store.commit('vacancy/setRequestingVacancyList', false)
        this.filterOfficeVacancyList(this.form.executorAssignmentType)

        setTimeout(() => {
          if (!this.vacancyList.length) {
            this.officeHasNoVacancy = true
            this.alertData = {
              message: 'Для вашего объекта нет ставок по вакансиям, обратитесь в поддержку ' +
                'или к вашему администратору, чтобы добавить ставку',
              actionName: 'поддержке',
              callback: () => {
                this.onDisableAlertData()
                this.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
              }
            }
            this.onEnableAlertData()
          } else {
            this.officeHasNoVacancy = false
          }
        }, 500)
      } catch (error) {
        console.log(error)
      }
    },
    async onOfferTypeChange ($event) {
      // this.$emit('update:offerAccessType', $event)
      this.form.vacancySelected = null
      this.form.costPerHour = ''
      this.form.selfEmployerCostPerHour = ''
      this.form.executorAssignmentType = $event

      if (this.getRequestingVacancyList) {
        return
      }

      if (!this.vacancyList.length) {
        await this.requestVacancyList()
      }

      this.filterOfficeVacancyList($event)
    },

    isPrivateOffer (executorAssignmentType) {
      const { executorCategory } = this.form
      if (!executorCategory || !(executorAssignmentType && executorAssignmentType.id)) {
        return false
      }
      const { id } = executorAssignmentType
      const privateTypes = [
        ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
        ALIAS_VACANCY_TYPE_INNER_WORKER,
      ]
      return privateTypes.includes(id) && executorCategory === EXECUTOR_CATEGORY_PRIVATE
    },
    filterOfficeVacancyList (executorAssignmentType) {
      const { executorCategory } = this.form
      if (!executorCategory || !this.hasRegion || !(executorAssignmentType && executorAssignmentType.id)) {
        return []
      }
      let filteredList = []
      if (this.isPrivateOffer(executorAssignmentType)) {
        filteredList = this.$store.state.vacancy.items.filter((prvt) => !!prvt.isPrivate)
      } else if (executorCategory === EXECUTOR_CATEGORY_PUBLIC) {
        filteredList = this.$store.state.vacancy.items.filter((pblc) => !pblc.isPrivate)
      }

      this.vacancyList = filteredList.map((item) => ({
        ...item,
        title: `${this.getPrefix(item)}${item.title}`
      }))
    }
  }
}
</script>

<style lang="sass" scoped>
.selector
  .input-date-wide
    width: 45px
</style>
