<template>
  <v-row
    no-gutters
    class="mt-4"
  >
    <v-col
      cols="12"
      :sm="12"
      :md="4"
      :lg="4"
      class="py-0 my-0"
    >
      <span>
        Телефон {{ progress }}
      </span>
      <v-card-actions>
        <!--        <v-autocomplete-->
        <!--          v-model="executor"-->
        <!--          :items="modelList"-->
        <!--          :value="executor"-->
        <!--          clearable-->
        <!--          hide-no-data-->
        <!--          hide-selected-->
        <!--          outlined-->
        <!--          rounded-->
        <!--          hide-details-->
        <!--          dense-->
        <!--          return-object-->
        <!--          :loading="loading"-->
        <!--          :search-input.sync="searchQuery"-->
        <!--          item-value="id"-->
        <!--          item-text="querySearch"-->
        <!--          placeholder="+7 (912) 345 67 80"-->
        <!--          persistent-hint-->
        <!--          @click:append="searchExecutor"-->
        <!--          @change="onOfferExecutorChange"-->
        <!--        >-->
        <!--          <template v-slot:selection="data">-->
        <!--            <OfferExecutorViewMinCard-->
        <!--              :executor="data.item"-->
        <!--            />-->
        <!--          </template>-->
        <!--          <template v-slot:item="data">-->
        <!--            <OfferExecutorViewMinCard-->
        <!--              :executor="data.item"-->
        <!--            />-->
        <!--          </template>-->
        <!--        </v-autocomplete>-->

        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          :loader-height="3"
          append-outer-icon=""
          class="mt-4"
          :disabled="loading"
          placeholder="7 (912) 345 67 80"
          :loading="loading"
          clearable
          type="number"
          @input="onInputChange"
          @click:click:clear="onInputChange"
        >
          <template v-slot:progress>
            <v-progress-linear
              v-if="loading"
              :color="'success'"
              indeterminate
              absolute
              height="3"
            />
          </template>
        </v-text-field>
        <v-spacer />
        <v-btn
          icon
          large
          :disabled="!canSearch"
          @click="searchExecutor"
        >
          <v-icon>
            mdi-account-search
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-col>
    <v-col
      cols="12"

      :sm="12"
      :md="8"
      :lg="8"
      class="py-0 my-0"
    >
      <OfferExecutorViewMinCard
        v-if="executor || executorNotFound"
        :executor="executor"
        :executor-not-found="executorNotFound"
      />
    </v-col>
  </v-row>
</template>

<script>
import OfferExecutorViewMinCard from '@/components/offer/offer-executor-min-card'
export const OFFER_FORM_EXECUTOR_CHANGE = 'offerFormExecutorChanged'
export default {
  name: 'OfferExecutorView',
  components: {
    OfferExecutorViewMinCard
  },
  props: {
    onOfferExecutorChange: {
      type: Function,
      required: false,
    },
    isPrivateCategory: Boolean
  },
  data: function () {
    return {
      modelList: [],
      executorNotFound: false,
      executor: null,
      executorListSize: 999,
      loading: false,
      searchQuery: '',
      // searchQuery: '79132065714',
      progress: '',
      custom: true,
    }
  },
  computed: {
    canSearch () {
      return !this.loading && this.searchQuery && this.searchQuery.trim() !== ''
    },
    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    },
    getExecutorFullNameLabel () {
      if (!this.executor || !this.executor.fullName) {
        return 'Не указано'
      }

      const { fullName, age } = this.executor
      let ageLabel = ''
      if (age) {
        ageLabel = `, ${age}`
      }

      return `${fullName}${ageLabel}`
    },
  },
  watch: {
    executor: function (val) {
      this.$emit(OFFER_FORM_EXECUTOR_CHANGE, this.executor)
    }
  },
  async created () {
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_EXECUTOR_CLEARED, () => {
        this.modelList = []
        this.searchQuery = ''
        this.executor = null
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_EXECUTOR_BLACKLISTED_UPDATED, ({ isBlackListed, activeRelay }) => {
        this.executor = {
          ...this.executor,
          isBlackListed,
          activeRelay,
        }

        this.modelList = this.modelList.map((item) => {
          if (item.id === this.executor.id) {
            return this.executor
          }
          return item
        })
      }
    )
  },
  methods: {
    updateExecutor (value) {
      this.executor = value
    },
    async  asyncSetSearch (value) {
      await this.$nextTick()
      this.searchQuery = value
    },
    async  onInputChange () {
      this.executorNotFound = false
      this.updateExecutor(null)
    },
    async searchExecutor () {
      if (this.searchQuery && this.searchQuery.length > 1) {
        this.updateExecutor(null)
        this.modelList = []
        this.loading = true
        this.executorNotFound = false

        const params = { 'searchQuery': this.searchQuery, 'page[number]': 1, 'page[size]': 50, 'onboarded': 1 }

        if (this.isPrivateCategory) {
          params['staffHost[id]'] = this.getMyOrganization.id
        }
        try {
          const list = await this.$store.dispatch(
            'users/searchLaborer',
            { params }
          )
          setTimeout(() => {
            this.loading = false
          }, 500)

          const responseData = list.data
          if (responseData) {
            if (responseData.length) {
              this.modelList = responseData.map((item) => (
                {
                  ...item,
                  querySearch: this.searchQuery
                }
              ))
              this.updateExecutor(this.modelList[0])
            } else {
              this.executorNotFound = true
            }

            // await this.asyncSetSearch(this.searchQuery)
          }
        } catch (error) {
          this.loading = false
        }
      }
    },
  }
}
</script>
